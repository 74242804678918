<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-switch
                    v-model="form.status"
                    inset
                    true-value="ativo"
                    false-value="inativo"
                    hide-details
                    dense
                    class="pb-2"
                />
                <v-spacer/>
                <span v-if="$vuetify.breakpoint.smAndUp" class="text-h5">
                    {{ form.id ? 'Edição' : 'Cadastro' }} de Complementos
                </span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row>
                    <v-col :cols="form.id ? '12' : '8'">
                        <v-text-field
                            label="Descrição"
                            v-model="form.descricao"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="!form.id" cols="4">
                        <v-text-field
                            label="Valor"
                            prefix="R$"
                            v-model="form.valor"
                            placeholder="0.00"
                            outlined
                            dense
                            hide-details
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="form.detalhes"
                            clear-icon="mdi-backspace-outline"
                            label="Detalhes/Ingredientes"
                            dense
                            hide-details
                            clearable
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            prepend-inner-icon="mdi-magnify"
                            v-model="form.categoria_id"
                            :items="categorias"
                            :search-input.sync="searchInput"
                            item-value="id"
                            item-text="descricao"
                            dense
                            label="Categoria"
                            placeholder="Selecione ou cadastre uma nova"
                            outlined
                            hide-details
                        >
                            <template #append>
                                <v-btn
                                    v-if="searchInput && !categorias.includes(searchInput)"
                                    @click="saveCategoria"
                                    small
                                    dense
                                    depressed
                                    outlined
                                >
                                    <v-icon>mdi-plus</v-icon>Adicionar
                                </v-btn>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-switch
                            dense
                            inset
                            class="py-2"
                            hide-details
                            v-model="form.controle_estoque"
                            label="Controlar estoque"
                        />
                        <v-text-field
                            v-if="form.controle_estoque"
                            v-model="form.estoque"
                            label="Quantidade disponível"
                            v-mask="['#######']"
                            placeholder="0"
                            type="tel"
                            outlined
                            hide-details
                            dense
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="mb-6" style="border: solid 1px #999; border-radius: 5px;">
                            <b>Imagem</b>
                            <ImageInput ref="imagem" v-model="form.imagens" />
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ImageInput from '@/components/ImageInput';

export default {
    name: 'Complementos',

    components: {
        ImageInput,
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        loading: false,
        form: {
            status: 'ativo',
            ativo: true,
            descricao: '',
            detalhes: '',
            categoria_id: '',
            valor: '',
            imagens: [],
            controle_estoque: false,
            estoque: '',
        },
        searchInput: '',
        categorias: [],
    }),

    mounted() {
        this.fillCategorias();

        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        },
    },

    methods: {
        fillCategorias() {
            this.categorias = [];
            this.$http.get('categorias').then(resp => {
                this.categorias = resp.data.data;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        saveCategoria() {
            if (!this.searchInput) {
                this.notify('Informe o nome da categoria', 'warning');
                return;
            }

            const categoria = this.categorias.find(e => e.descricao == this.searchInput)

            if (categoria) {
                this.categoria_id = categoria;
                return;
            }

            this.$http.post('categorias', {descricao: this.searchInput}).then(resp => {
                const categoria = resp.data.data;
                this.categorias.push(categoria);
                this.categoria_id = categoria;
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        salvar() {
            const id = this.form.id;

            if (!this.form.descricao) {
                this.notify('Informe a descrição', 'warning');
                return;
            }

            if (!this.form.categoria_id) {
                this.notify('Informe a categoria', 'warning');
                return;
            }

            if (id) {
                this.loading = true;
                this.$http.put(`complementos/${id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('complementos', this.form).then(() => {
                this.$emit('onCadastro');
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
